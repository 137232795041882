import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTheme } from '../Flowbite/ThemeContext';
const PaginationButton = ({ active, onClick, children, className }) => {
    const theme = useTheme().theme.pagination;
    return (_jsx("button", { className: classNames({
            [theme.pages.selector.active]: active,
        }, className), onClick: onClick, children: children }));
};
export default PaginationButton;
